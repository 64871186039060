import Img from "gatsby-image";
import React from "react";

import { FluidObject } from "gatsby-image";
import { Helmet } from "react-helmet";

import { FacebookShareButton } from "react-share";
import { Fragment } from "react";
import { LinkedinShareButton } from "react-share";
import { TwitterShareButton } from "react-share";
import { WhatsappShareButton } from "react-share";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { config } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { graphql } from "gatsby";

import parse from "html-react-parser";

import Breadcrumbs from "@Js/Component/Breadcrumbs/Breadcrumbs";

import classNames from "@Css/Component/Template/Post.module.scss";

import "@Css/Wordpress/EntryContent.module.scss";
import "@Css/Wordpress/WpBlockButtons.module.scss";
import "@Css/Wordpress/WpBlockColumns.module.scss";
import "@Css/Wordpress/WpBlockCover.module.scss";
import "@Css/Wordpress/WpBlockGallery.module.scss";
import "@Css/Wordpress/WpBlockImage.module.scss";
import "@Css/Wordpress/WpBlockLatestPosts.module.scss";
import "@Css/Wordpress/WpBlockQuote.module.scss";
import "@Css/Wordpress/WpBlockSeparator.module.scss";
import "@Css/Wordpress/WpBlockTable.module.scss";

config.autoAddCss = false;

export type PostProps = Readonly<{
    data: {
        wordpressPost: {
            acf: {
                read: string;
            };
            categories: {
                name: string;
                wordpress_id: number;
            }[];
            content: string;
            date: string;
            featured_media: {
                localFile: {
                    childImageSharp: {
                        fluid: FluidObject;
                    };
                };
            };
            title: string;
            yoast_breadcrumbs: {
                text: string;
                url: string;
            }[];
            yoast_head: string;
        };
    };
}>;

export const postQuery = graphql`
    query($id: String!) {
        wordpressPost(id: { eq: $id }) {
            acf {
                read
            }
            categories {
                name
                wordpress_id
            }
            content
            date(formatString: "D MMMM YYYY", locale: "NL-nl")
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            title
            yoast_breadcrumbs {
                text
                url
            }
            yoast_head
        }
    }
`;

const Post = (props: PostProps) => {
    const post = props.data.wordpressPost;

    return (
        <Fragment>
            <Helmet>
                { parse(post.yoast_head) }
            </Helmet>
            <Breadcrumbs breadcrumbs={ post.yoast_breadcrumbs } />
            <main>
                <article>
                    <header className={ classNames.header }>
                        <Img className={ classNames.featuredImage } fluid={ post.featured_media.localFile.childImageSharp.fluid } />
                        <div className={ classNames.categories }>
                            { post.categories.map((category) => (
                                <div className={ classNames.category } key={ category.wordpress_id }>{ category.name }</div>
                            )) }
                        </div>
                        <h1 className={ classNames.title }>{ parse(post.title) }</h1>
                    </header>
                    <div className={ classNames.info }>
                        <time className={ classNames.date }>{ post.date }</time>
                        <span className={ classNames.read }>Read { post.acf.read } min.</span>
                        <div className={ classNames.share }>
                            <span>Delen: </span>
                            <FacebookShareButton url={ typeof window == "undefined" ? "" : window.location.href }>
                                <FontAwesomeIcon icon={ faFacebookF as unknown as "facebook-f" } />
                            </FacebookShareButton>
                            <TwitterShareButton url={ typeof window == "undefined" ? "" : window.location.href }>
                                <FontAwesomeIcon icon={ faTwitter as unknown as "twitter" } />
                            </TwitterShareButton>
                            <LinkedinShareButton url={ typeof window == "undefined" ? "" : window.location.href }>
                                <FontAwesomeIcon icon={ faLinkedinIn as unknown as "linkedin-in" } />
                            </LinkedinShareButton>
                            <WhatsappShareButton url={ typeof window == "undefined" ? "" : window.location.href }>
                                <FontAwesomeIcon icon={ faWhatsapp as unknown as "whatsapp" } />
                            </WhatsappShareButton>
                        </div>
                    </div>
                    <div className="entry-content">
                        { parse(post.content) }
                    </div>
                </article>
            </main>
        </Fragment>
    );
};

export default Post;
